import Icon from '@ant-design/icons';

export function Sun() {
    return (
        <Icon
            component={
                () => (
                    <svg focusable="false" xmlns="http://www.w3.org/2000/svg"
                        enableBackground="new 0 0 24 24" height="14" viewBox="0 0 24 24" width="14">
                        <rect fill="none" height="14" width="14"></rect>
                        <path fill="white" d="M12,7c-2.76,0-5,2.24-5,5s2.24,5,5,5s5-2.24,5-5S14.76,7,12,7L12,7z M2,13l2,0c0.55,0,1-0.45,1-1s-0.45-1-1-1l-2,0 c-0.55,0-1,0.45-1,1S1.45,13,2,13z M20,13l2,0c0.55,0,1-0.45,1-1s-0.45-1-1-1l-2,0c-0.55,0-1,0.45-1,1S19.45,13,20,13z M11,2v2 c0,0.55,0.45,1,1,1s1-0.45,1-1V2c0-0.55-0.45-1-1-1S11,1.45,11,2z M11,20v2c0,0.55,0.45,1,1,1s1-0.45,1-1v-2c0-0.55-0.45-1-1-1 C11.45,19,11,19.45,11,20z M5.99,4.58c-0.39-0.39-1.03-0.39-1.41,0c-0.39,0.39-0.39,1.03,0,1.41l1.06,1.06 c0.39,0.39,1.03,0.39,1.41,0s0.39-1.03,0-1.41L5.99,4.58z M18.36,16.95c-0.39-0.39-1.03-0.39-1.41,0c-0.39,0.39-0.39,1.03,0,1.41 l1.06,1.06c0.39,0.39,1.03,0.39,1.41,0c0.39-0.39,0.39-1.03,0-1.41L18.36,16.95z M19.42,5.99c0.39-0.39,0.39-1.03,0-1.41 c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06c-0.39,0.39-0.39,1.03,0,1.41s1.03,0.39,1.41,0L19.42,5.99z M7.05,18.36 c0.39-0.39,0.39-1.03,0-1.41c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06c-0.39,0.39-0.39,1.03,0,1.41s1.03,0.39,1.41,0L7.05,18.36z">
                        </path>
                    </svg>
                )
            }
        />
    );
}

export function Moon() {
    return (
        <Icon
            component={
                () => (
                    <svg focusable="false" xmlns="http://www.w3.org/2000/svg"
                        enableBackground="new 0 0 24 24" height="14" viewBox="0 0 24 24" width="14">
                        <rect fill="none" height="14" width="14"></rect>
                        <path fill="white" d="M12,3c-4.97,0-9,4.03-9,9s4.03,9,9,9s9-4.03,9-9c0-0.46-0.04-0.92-0.1-1.36c-0.98,1.37-2.58,2.26-4.4,2.26 c-2.98,0-5.4-2.42-5.4-5.4c0-1.81,0.89-3.42,2.26-4.4C12.92,3.04,12.46,3,12,3L12,3z">
                        </path>
                    </svg>
                )
            }

        />

    );
}

export function Logo({ width = 200, height = 60 }) {
    return (
        <Icon style={{ marginTop: "10px", marginLeft: "5px" }}
            component={
                () => (


                    <svg xmlns="http://www.w3.org/2000/svg"  width="226" height="43" viewBox="0 0 226 43">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_20049" data-name="Rectangle 20049" width="73.298" height="24" fill="none"/>
    </clipPath>
  </defs>
  <g id="Group_16606" data-name="Group 16606" transform="translate(-100 -36)">
    <text id="Skill_Master_" data-name="Skill Master " color="balck" transform="translate(196 58)" fill="#fff" font-size="21" font-family="Poppins-Bold, Poppins" font-weight="700"><tspan x="0" y="0">Skill Master </tspan></text>
    <text id="Games" transform="translate(198 74)" fill="#fff" font-size="15" font-family="Poppins-Bold, Poppins" font-weight="700" letter-spacing="1.073em"><tspan x="0" y="0">Games</tspan></text>
    <g id="Group_15825" data-name="Group 15825" transform="translate(6 9)">
      <rect id="Rectangle_20050" data-name="Rectangle 20050" width="88" height="36" rx="3" transform="translate(94 31)" fill="#3b0568"/>
      <g id="Group_15822" data-name="Group 15822" transform="translate(101 37)">
        <g id="Group_15821" data-name="Group 15821" transform="translate(0 0)" clip-path="url(#clip-path)">
          <path id="Path_25287" data-name="Path 25287" d="M145.037,15.929c.232-2.251-.225-4.529.285-6.772.611-2.685,2.2-4.261,4.888-4.341,4-.119,8.019-.129,12.023,0a5.2,5.2,0,0,1,5.115,5.172,1.655,1.655,0,0,1-.044.853c-.258.462-4.536.288-4.712-.228-.315-.923-.983-1.068-1.766-1.071-3.079-.011-6.159,0-9.237-.007-1.164,0-1.683.53-1.659,1.7.057,2.729.071,5.459.1,8.188-.05,1.052-.525,2.266,1.031,2.706,3.2.022,6.4.013,9.6.078,1.906.039,1.837-1.173,1.948-2.553.133-1.649-.855-1.416-1.835-1.41-1.687.01-3.375-.022-5.062.012-.8.016-1.086-.247-1.134-1.1-.208-3.671-.24-3.669,3.407-3.669,2.677,0,5.354.039,8.03-.02,1-.022,1.469.157,1.413,1.314-.1,2.033.012,4.075-.04,6.111-.1,3.845-1.975,5.86-5.765,5.97-3.662.106-7.329.013-10.994,0a5.552,5.552,0,0,1-4.537-2.167,8.223,8.223,0,0,1-.975-3.437c-.066-1.778-.054-3.559-.075-5.339" transform="translate(-94.13 -3.065)" fill="#fef9fa"/>
          <path id="Path_25288" data-name="Path 25288" d="M22.24,17.459c.05,1.96.373,3.921-.247,5.866a4.684,4.684,0,0,1-4.444,3.534A107.087,107.087,0,0,1,5,26.868,5.137,5.137,0,0,1,.16,21.487a1.258,1.258,0,0,1,.095-.663c.359-.513,4.372-.259,4.651.34A1.853,1.853,0,0,0,6.864,22.27c2.906-.024,5.813-.043,8.719.006,1.4.024,2.022-.576,2.039-1.961.017-1.363-.515-2.037-1.957-2.015-2.964.046-5.929.037-8.893,0-2.4-.028-4.788-.348-5.886-2.855a9.011,9.011,0,0,1,.3-8.381A4.736,4.736,0,0,1,5.5,4.849c3.835-.049,7.672-.02,11.508-.013a6.781,6.781,0,0,1,3.838,1.452,7.727,7.727,0,0,1,1.447,3.3,2.59,2.59,0,0,1-.109,1.341c-.154.371-4.345.295-4.519-.108-.814-1.887-2.4-1.107-3.739-1.165-2.383-.019-4.766-.022-7.149-.065-1.294-.023-1.932.527-1.93,1.858s.472,2.121,1.914,2.108c2.732-.024,5.465-.031,8.2-.044,2.989-.064,5.774.279,7.07,3.572.006.191-.081.45.279.384Z" transform="translate(0 -3.129)" fill="#fef9fa"/>
          <path id="Path_25289" data-name="Path 25289" d="M72.707,2.03c3.231,3.668,6.871,6.932,10.25,10.455.746.777,1.11.084,1.532-.337,3.052-3.039,6.086-6.1,9.149-9.124.314-.31.806-1.365,1.168.015-.173,3.246-.159,6.492-.011,9.738q-.038,6.127-.076,12.254l-.669.18c-1.315-.187-3.032.536-3.864-.336-.895-.936-.253-2.662-.291-4.041-.06-2.151-.021-4.3-.03-6.455a4.345,4.345,0,0,0-.172-.65c-1.765,1.763-3.474,3.361-5.045,5.084-.84.921-1.36.894-2.17-.006-1.549-1.72-3.2-3.346-4.811-5.011l-.439.151v7.895c0,3.4,0,3.4-3.424,3.317-.545-.014-1.2.213-1.531-.5q.02-3.824.04-7.648Q72.3,9.654,72.3,2.294l.41-.264" transform="translate(-46.906 -1.318)" fill="#fef9fa"/>
          <path id="Path_25290" data-name="Path 25290" d="M96.988,8.805c-.375-.337-.733-.625-1.052-.951C94.806,6.7,92.9,5.6,92.785,4.346c-.12-1.312,2.043-2.281,3.03-3.551C96.628-.251,97.28-.274,98.086.783a15.906,15.906,0,0,0,1.589,1.6c1.919,1.881,1.919,1.88-.035,3.843-.655.657-1.3,1.326-1.956,1.981-.2.2-.43.369-.7.594" transform="translate(-60.215 0)" fill="#fef9fa"/>
          <path id="Path_25291" data-name="Path 25291" d="M62.749,39.762l.278.384c-.36.066-.273-.192-.278-.384" transform="translate(-40.721 -25.806)" fill="#fe0113"/>
        </g>
      </g>
    </g>
  </g>
</svg>




                )
            }
        />

    );
}

export function LogoSmall({ width = 70, height = 50 }) {
    return (
        <Icon style={{ marginTop: "5px", marginLeft: "10px" }}
            component={
                () => (
                    <svg xmlns="http://www.w3.org/2000/svg"  width="60" height="43" viewBox="0 0 226 43">
                    <defs>
                      <clipPath id="clip-path">
                        <rect id="Rectangle_20049" data-name="Rectangle 20049" width="73.298" height="24" fill="none"/>
                      </clipPath>
                    </defs>
                    <g id="Group_16606" data-name="Group 16606" transform="translate(-100 -36)">
                      <text id="Skill_Master_" data-name="Skill Master " color="balck" transform="translate(196 58)" fill="#fff" font-size="21" font-family="Poppins-Bold, Poppins" font-weight="700"><tspan x="0" y="0">Skill Master </tspan></text>
                      <text id="Games" transform="translate(198 74)" fill="#fff" font-size="15" font-family="Poppins-Bold, Poppins" font-weight="700" letter-spacing="1.073em"><tspan x="0" y="0">Games</tspan></text>
                      <g id="Group_15825" data-name="Group 15825" transform="translate(6 9)">
                        <rect id="Rectangle_20050" data-name="Rectangle 20050" width="88" height="36" rx="3" transform="translate(94 31)" fill="#3b0568"/>
                        <g id="Group_15822" data-name="Group 15822" transform="translate(101 37)">
                          <g id="Group_15821" data-name="Group 15821" transform="translate(0 0)" clip-path="url(#clip-path)">
                            <path id="Path_25287" data-name="Path 25287" d="M145.037,15.929c.232-2.251-.225-4.529.285-6.772.611-2.685,2.2-4.261,4.888-4.341,4-.119,8.019-.129,12.023,0a5.2,5.2,0,0,1,5.115,5.172,1.655,1.655,0,0,1-.044.853c-.258.462-4.536.288-4.712-.228-.315-.923-.983-1.068-1.766-1.071-3.079-.011-6.159,0-9.237-.007-1.164,0-1.683.53-1.659,1.7.057,2.729.071,5.459.1,8.188-.05,1.052-.525,2.266,1.031,2.706,3.2.022,6.4.013,9.6.078,1.906.039,1.837-1.173,1.948-2.553.133-1.649-.855-1.416-1.835-1.41-1.687.01-3.375-.022-5.062.012-.8.016-1.086-.247-1.134-1.1-.208-3.671-.24-3.669,3.407-3.669,2.677,0,5.354.039,8.03-.02,1-.022,1.469.157,1.413,1.314-.1,2.033.012,4.075-.04,6.111-.1,3.845-1.975,5.86-5.765,5.97-3.662.106-7.329.013-10.994,0a5.552,5.552,0,0,1-4.537-2.167,8.223,8.223,0,0,1-.975-3.437c-.066-1.778-.054-3.559-.075-5.339" transform="translate(-94.13 -3.065)" fill="#fef9fa"/>
                            <path id="Path_25288" data-name="Path 25288" d="M22.24,17.459c.05,1.96.373,3.921-.247,5.866a4.684,4.684,0,0,1-4.444,3.534A107.087,107.087,0,0,1,5,26.868,5.137,5.137,0,0,1,.16,21.487a1.258,1.258,0,0,1,.095-.663c.359-.513,4.372-.259,4.651.34A1.853,1.853,0,0,0,6.864,22.27c2.906-.024,5.813-.043,8.719.006,1.4.024,2.022-.576,2.039-1.961.017-1.363-.515-2.037-1.957-2.015-2.964.046-5.929.037-8.893,0-2.4-.028-4.788-.348-5.886-2.855a9.011,9.011,0,0,1,.3-8.381A4.736,4.736,0,0,1,5.5,4.849c3.835-.049,7.672-.02,11.508-.013a6.781,6.781,0,0,1,3.838,1.452,7.727,7.727,0,0,1,1.447,3.3,2.59,2.59,0,0,1-.109,1.341c-.154.371-4.345.295-4.519-.108-.814-1.887-2.4-1.107-3.739-1.165-2.383-.019-4.766-.022-7.149-.065-1.294-.023-1.932.527-1.93,1.858s.472,2.121,1.914,2.108c2.732-.024,5.465-.031,8.2-.044,2.989-.064,5.774.279,7.07,3.572.006.191-.081.45.279.384Z" transform="translate(0 -3.129)" fill="#fef9fa"/>
                            <path id="Path_25289" data-name="Path 25289" d="M72.707,2.03c3.231,3.668,6.871,6.932,10.25,10.455.746.777,1.11.084,1.532-.337,3.052-3.039,6.086-6.1,9.149-9.124.314-.31.806-1.365,1.168.015-.173,3.246-.159,6.492-.011,9.738q-.038,6.127-.076,12.254l-.669.18c-1.315-.187-3.032.536-3.864-.336-.895-.936-.253-2.662-.291-4.041-.06-2.151-.021-4.3-.03-6.455a4.345,4.345,0,0,0-.172-.65c-1.765,1.763-3.474,3.361-5.045,5.084-.84.921-1.36.894-2.17-.006-1.549-1.72-3.2-3.346-4.811-5.011l-.439.151v7.895c0,3.4,0,3.4-3.424,3.317-.545-.014-1.2.213-1.531-.5q.02-3.824.04-7.648Q72.3,9.654,72.3,2.294l.41-.264" transform="translate(-46.906 -1.318)" fill="#fef9fa"/>
                            <path id="Path_25290" data-name="Path 25290" d="M96.988,8.805c-.375-.337-.733-.625-1.052-.951C94.806,6.7,92.9,5.6,92.785,4.346c-.12-1.312,2.043-2.281,3.03-3.551C96.628-.251,97.28-.274,98.086.783a15.906,15.906,0,0,0,1.589,1.6c1.919,1.881,1.919,1.88-.035,3.843-.655.657-1.3,1.326-1.956,1.981-.2.2-.43.369-.7.594" transform="translate(-60.215 0)" fill="#fef9fa"/>
                            <path id="Path_25291" data-name="Path 25291" d="M62.749,39.762l.278.384c-.36.066-.273-.192-.278-.384" transform="translate(-40.721 -25.806)" fill="#fe0113"/>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>

                )
            }
        />
    );
}

export function MenuFold() {
    return (
        <Icon
            component={
                () => (
                    <svg xmlns="http://www.w3.org/2000/svg" color='#3b0568' width="28.467" height="25.032" viewBox="0 0 28.467 25.032">
                        <g id="Group_661" data-name="Group 661" transform="translate(-2231.31 -198.472)">
                            <g id="Group_660" data-name="Group 660" transform="translate(-194.102 -183.834)">
                                <rect id="Rectangle_75" data-name="Rectangle 75" width="5.607" height="28.037" rx="2.804" transform="translate(2453.449 391.997) rotate(90)" fill="#3b0568" />
                                <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(2430.846 391.306)" fill="#fff" stroke="#3b0568" strokeWidth="1">
                                    <circle cx="3.516" cy="3.516" r="3.516" stroke="none" />
                                    <circle cx="3.516" cy="3.516" r="3.016" fill="none" />
                                </g>
                                <rect id="Rectangle_19176" data-name="Rectangle 19176" width="5.607" height="21.866" rx="2.804" transform="translate(2447.277 382.997) rotate(90)" fill="#3b0568" />
                                <g id="Ellipse_99" data-name="Ellipse 99" transform="translate(2441.846 382.306)" fill="#fff" stroke="#3b0568" strokeWidth="1">
                                    <circle cx="3.516" cy="3.516" r="3.516" stroke="none" />
                                    <circle cx="3.516" cy="3.516" r="3.016" fill="none" />
                                </g>
                                <rect id="Rectangle_19177" data-name="Rectangle 19177" width="5.607" height="28.037" rx="2.804" transform="translate(2453.449 400.997) rotate(90)" fill="#3b0568" />
                                <g id="Ellipse_100" data-name="Ellipse 100" transform="translate(2446.846 400.306)" fill="#fff" stroke="#3b0568" strokeWidth="1">
                                    <circle cx="3.516" cy="3.516" r="3.516" stroke="none" />
                                    <circle cx="3.516" cy="3.516" r="3.016" fill="none" />
                                </g>
                            </g>
                        </g>
                    </svg>

                )
            }
        />
    );
}

export function TireIcon({ height = 45, width = 45 }) {
    return (
        <Icon
            spin={true}
            component={
                () => (
                    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 44.634 44.744">
                        <g id="tire" transform="translate(0 -0.002)">
                            <path id="Path_141" data-name="Path 141" d="M42.493,12.679a.868.868,0,0,0,.177,1.13,3.981,3.981,0,0,1,1.044,3.273,1.066,1.066,0,0,0,.156.754,3.741,3.741,0,0,1,.408,3.609c-.2.466.326.685.318,1.026-.026,1.018.245,2.068-.368,3.036-.462.73.1,1.551-.2,2.4a18.659,18.659,0,0,1-2.017,4.875A34.523,34.523,0,0,1,39.93,36.1a23.355,23.355,0,0,1-3.23,3.3c-1.286.93-2.409,2.2-3.941,2.692-1.443.467-2.682,1.576-4.2,1.608-1.408.029-2.572.937-3.962.829-1.575-.122-3.21.518-4.69.032-1.429-.469-3.024-.139-4.294-.927-1.211-.751-2.763-.691-3.808-1.626-.975-.874-2.257-1.2-3.2-2.063A23.09,23.09,0,0,1,5.3,36.72c-.951-1.318-2.243-2.47-2.756-4.034-.461-1.4-1.54-2.62-1.555-4.1-.013-1.3-.833-2.35-.792-3.62.019-.581-.368-1.184.194-1.728.135-.13.042-.42-.043-.653a3.813,3.813,0,0,1,.17-3.517c.288-.456-.2-.847-.089-1.153.376-1.046.35-2.181,1.115-3.177.5-.645.365-1.793.9-2.581.292-.429.273-1.151,1.066-1.167.111,0,.258-.359.314-.571a4.26,4.26,0,0,1,2.244-2.9,1.092,1.092,0,0,0,.574-.536A3.848,3.848,0,0,1,9.253,4.622a1.228,1.228,0,0,0,.573-.507,3.934,3.934,0,0,1,3.129-1.766c.216,0,.535-.062.627-.208A2.949,2.949,0,0,1,16.165.968C17.5.934,18.585.071,19.9.153c.5.032,1.034-.315,1.5.217.135.153.474,0,.743-.075a4.017,4.017,0,0,1,3.434.181c.159.1.419.229.538.166a2.974,2.974,0,0,1,2.922.425c1.077.607,2.328.621,3.336,1.3.492.33,1.215.365,1.343,1.165.027.168.4.358.635.4,1.306.249,1.98,1.316,2.928,2.059A20.76,20.76,0,0,1,40.772,9.86a19.857,19.857,0,0,1,1.721,2.819" transform="translate(0 0.001)" fill="#003d78" />
                            <path id="Path_142" data-name="Path 142" d="M44.514,28.933A15.576,15.576,0,1,1,28.967,44.564,15.5,15.5,0,0,1,44.514,28.933" transform="translate(-22.203 -22.176)" fill="#fdfdfd" />
                            <path id="Path_143" data-name="Path 143" d="M33.188,47.75A14.59,14.59,0,1,1,47.814,62.333,14.625,14.625,0,0,1,33.188,47.75" transform="translate(-25.438 -25.41)" fill="#f9423a" />
                            <path id="Path_144" data-name="Path 144" d="M122.768,85.738A14.076,14.076,0,0,1,118.6,95.779c-.481.489-.731.593-1.186-.067-1.2-1.747-2.515-3.419-3.75-5.145a3.556,3.556,0,0,1,1.624-5.464c2.132-.777,4.324-1.389,6.477-2.111.468-.157.6-.023.715.4a11.372,11.372,0,0,1,.292,2.349" transform="translate(-86.584 -63.562)" fill="#fdfdfd" />
                            <path id="Path_145" data-name="Path 145" d="M104.317,47.636a3.447,3.447,0,0,1-3.58-3.369c-.09-2.24-.007-4.485-.04-6.728-.009-.605.3-.613.719-.542a14.02,14.02,0,0,1,10.659,7.851c.121.237.321.526-.1.638-2.564.687-5,1.882-7.659,2.149" transform="translate(-77.18 -28.333)" fill="#fdfdfd" />
                            <path id="Path_146" data-name="Path 146" d="M52.876,40.748c0,1,.024,2.01-.005,3.014a3.621,3.621,0,0,1-4.629,3.483c-2.144-.614-4.24-1.4-6.374-2.051-.54-.166-.575-.348-.338-.79a14.079,14.079,0,0,1,10.531-7.668c.7-.118.856.118.828.767-.046,1.08-.012,2.163-.013,3.245" transform="translate(-31.725 -28.134)" fill="#fdfdfd" />
                            <path id="Path_147" data-name="Path 147" d="M36.37,86.119c-.006-3.195-.005-3.2,2.965-2.25,1.431.456,2.87.886,4.285,1.389A3.561,3.561,0,0,1,45.4,90.733c-1.238,1.771-2.569,3.476-3.806,5.247-.431.618-.689.468-1.118.031a13.906,13.906,0,0,1-4.1-9.891" transform="translate(-27.876 -63.862)" fill="#fdfdfd" />
                            <path id="Path_148" data-name="Path 148" d="M72.9,124.3a15.108,15.108,0,0,1-6.353-1.509c-.652-.315-.771-.555-.312-1.166,1.275-1.693,2.468-3.447,3.733-5.147a3.562,3.562,0,0,1,5.7-.159c1.418,1.775,2.666,3.683,4.025,5.506.421.565.054.714-.337.924A13.941,13.941,0,0,1,72.9,124.3" transform="translate(-50.557 -88.123)" fill="#fdfdfd" />
                            <path id="Path_149" data-name="Path 149" d="M87.824,90.179a2.388,2.388,0,1,1,.05-4.775,2.376,2.376,0,0,1,2.337,2.365,2.324,2.324,0,0,1-2.388,2.41" transform="translate(-65.486 -65.459)" fill="#fefefe" />
                            <path id="Path_150" data-name="Path 150" d="M93.642,40.867c0-1.276-.009-2.552.007-3.828,0-.3-.168-.725.421-.756.568-.03.5.34.5.683q0,3.886,0,7.772c0,.338.075.773-.428.794-.6.025-.494-.469-.5-.837-.008-1.276,0-2.552,0-3.828" transform="translate(-71.765 -27.808)" fill="#fdfdfd" />
                            <path id="Path_151" data-name="Path 151" d="M65.546,111.887c-.069.112-.176.313-.308.5q-2.248,3.092-4.507,6.176c-.178.242-.273.7-.776.347-.468-.324-.2-.569.005-.85q2.285-3.136,4.569-6.274c.185-.254.359-.529.733-.44.209.05.3.219.285.545" transform="translate(-45.755 -85.327)" fill="#fafafa" />
                            <path id="Path_152" data-name="Path 152" d="M112.4,118.575a3.371,3.371,0,0,1-.394.323c-.369.227-.471-.135-.611-.325-1.009-1.367-2-2.743-3-4.118-.567-.781-1.124-1.569-1.7-2.346-.177-.239-.288-.481-.065-.723a.428.428,0,0,1,.7.062q2.508,3.4,4.984,6.828a1.158,1.158,0,0,1,.083.3" transform="translate(-81.635 -85.254)" fill="#fafafa" />
                            <path id="Path_153" data-name="Path 153" d="M47.856,78.737c-.108.436-.356.485-.711.371q-3.89-1.259-7.785-2.506c-.3-.1-.45-.282-.332-.609s.379-.333.659-.242c2.557.829,5.111,1.664,7.67,2.486.287.092.482.216.5.5" transform="translate(-29.884 -58.019)" fill="#f7f7f7" />
                            <path id="Path_154" data-name="Path 154" d="M123.566,76.426c.092.548-.291.5-.508.57q-3.675,1.214-7.361,2.395c-.324.1-.751.254-.9-.2s.3-.553.619-.656c2.456-.793,4.916-1.573,7.371-2.367.4-.13.786-.308.775.255" transform="translate(-87.971 -58.281)" fill="#f8f8f8" />
                            <path id="Path_155" data-name="Path 155" d="M134.639,88.624a13.112,13.112,0,0,1-3.419,8.852c-.48.551-.8.626-1.159-.061a2.146,2.146,0,0,0-.41-.558c-.624-.592-.5-1.055.035-1.7a9.816,9.816,0,0,0,2.269-6.984c-.042-.814.134-1.141.915-1.364,1.726-.492,1.714-.536,1.768,1.238.006.193,0,.386,0,.579" transform="translate(-99.053 -66.31)" fill="#003d78" />
                            <path id="Path_156" data-name="Path 156" d="M103.409,41.38c0-1.548,0-1.55,1.376-1.193a13.28,13.28,0,0,1,8.271,6.224c.361.594.267.859-.391.989a2.6,2.6,0,0,0-.657.214c-.84.423-1.306.176-1.822-.64a9.21,9.21,0,0,0-5.561-4.07c-1.034-.271-1.459-.671-1.216-1.525" transform="translate(-79.21 -30.653)" fill="#003d78" />
                            <path id="Path_157" data-name="Path 157" d="M55.019,41.246c.315,1.092-.083,1.475-1.007,1.709a9.94,9.94,0,0,0-5.919,4.368c-.168.252-.2.76-.692.616a15.817,15.817,0,0,1-2.16-.771c-.211-.1.072-.416.174-.595a13,13,0,0,1,8.965-6.556c1.426-.3.324,1,.639,1.229" transform="translate(-34.618 -30.637)" fill="#003d78" />
                            <path id="Path_158" data-name="Path 158" d="M39.411,86.015a.789.789,0,0,1,.185-.021c2.26.636,2.243.637,2.371,2.951a10.141,10.141,0,0,0,2.347,5.922c.2.243.671.436.4.847a14.467,14.467,0,0,1-1.4,1.824c-.149.163-.372-.185-.514-.339a12.912,12.912,0,0,1-3.391-11.184" transform="translate(-30.073 -65.909)" fill="#003d78" />
                            <path id="Path_159" data-name="Path 159" d="M75.817,140.747a12.911,12.911,0,0,1-5.6-1.213c-.493-.214-.691-.439-.268-.9a2.312,2.312,0,0,0,.4-.564c.434-.934.978-1.041,1.985-.666a9.531,9.531,0,0,0,6.805.018c1.107-.419,1.566-.134,2.187.741s.556,1.22-.436,1.581a13.6,13.6,0,0,1-5.071,1" transform="translate(-53.435 -105.16)" fill="#003d78" />
                            <rect id="Rectangle_24" data-name="Rectangle 24" width="44.633" height="44.745" transform="translate(0 0.001)" fill="none" />
                        </g>
                    </svg>
                )
            }
        />
    );
}